import { Col, Container, Row, Table, Tabs, Tab } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { ThreeDotSpinner } from "../loader";
import Pagination from "react-js-pagination";
import { apiService } from "../../service/api.service";

const PayoutTable = ({ payoutData, page, totalItems, onPageChange, selectedRows, setSelectedRows }) => {
  
  const handleSelectAllChange = (checked) => {
    if(checked) {
      const UserIds = payoutData.map(data => data.userId);
      setSelectedRows(UserIds);
    } else {
      setSelectedRows([])
    } 
  }

  const handleCheckboxChange = (id,checked) =>{
    if (checked) {
      setSelectedRows((prev) => [...prev, id]);
    } else {
      setSelectedRows((prev) => prev.filter((selectedId) => selectedId !== id));
    }
  }

  return (
    <div className="audit-request-box">
      <Table responsive="sm" className="UserListTable">
        <thead>
          <tr>
          <th>
              <input
                type="checkbox"
                checked={selectedRows.length === (payoutData?.length || 0)}  
                onChange={(e) => handleSelectAllChange(e.target.checked)}
              />
            </th>
            <th>User</th>
            <th>Name</th>
            <th>Account Number</th>
            <th>Bank</th>
            <th>NIT</th>
            <th>RUT</th>
            <th>Total Payable</th>
            <th>Pay</th>
          </tr>
        </thead>
        <tbody>
          {payoutData && payoutData.map((data, index) => (
            <tr key={index}>
               <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(data.userId)}  
                  onChange={(e) => handleCheckboxChange(data.userId, e.target.checked)} 
                />
              </td>
              <td>{data.userId}</td>
              <td>{data?.name}</td>
              <td>{data?.accountNumber ? data.accountNumber : "N/A"}</td>
              <td>{data?.bank ? data.bank : "N/A"}</td>
              <td>{data?.nit ? data.nit : "N/A"}</td>
              <td>{data?.rut ? data.rut : "N/A"}</td>
              <td>${data?.totalPayable?.toFixed(2)}</td>
              <td><p>Pay</p></td>
            </tr>
          ))}
          {payoutData && payoutData.length === 0 && (
            <tr>
              <td colSpan={7}>
                <div className="text-center">
                  <div className="no-data-found-section">
                    <img src={require("../../assets/images/no-data.svg").default} alt="" />
                    <h5><b>No data found</b></h5>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {payoutData && payoutData.length > 0 && (
        <Pagination
          activePage={page + 1}
          itemsCountPerPage={10}
          totalItemsCount={totalItems}
          pageRangeDisplayed={5}
          onChange={onPageChange}
          prevPageText={"Prev"}
          nextPageText={"Next"}
        />
      )}
    </div>
  );
};

export const PayoutManagement = () => {
  const [loader, setLoader] = useState(false);
  const [payoutData, setPayoutData] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);  
  const [totalItems, setTotalItems] = useState(0);
  const [type, setType] = useState("ROLE_CULTIVATOR");
  const [selectedRows, setSelectedRows] = useState([]); 


  useEffect(() => {
    getPayout(page, size, type);
  }, [type, page, size]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber - 1);  
  };

  const getPayout = (page, size, type) => {
    setLoader(true);

    apiService.getPayout(page, size, type)
      .then((res) => {
        setTotalItems(res.data.data.total);
        setPayoutData(res.data.data.list);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        
      });
  };

  const handleTabSelect = (key) => {
    setType(key);
    setSelectedRows([]);
  };

  return (
    <>
      <div className="dashboard-main-area">
        {loader && <ThreeDotSpinner />}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="transaction-management-top">
                  <div className="heading-top-area">
                    <div className="d-flex ">
                      <h2 className="align-self-center m-0">Payout Management</h2>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <Tabs
                  activeKey={type}
                  onSelect={handleTabSelect}
                  id="uncontrolled-tab-example"
                  className="prediction-tab mb-3"
                >
                  <Tab eventKey="ROLE_CULTIVATOR" title="Cultivator">
                    <PayoutTable
                      payoutData={payoutData}
                      page={page}
                      totalItems={totalItems}
                      onPageChange={handlePageChange}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                  </Tab>
                  <Tab eventKey="ROLE_DRIVER" title="Driver">
                    <PayoutTable
                      payoutData={payoutData}
                      page={page}
                      totalItems={totalItems}
                      onPageChange={handlePageChange}
                      selectedRows={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};