import React, { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row, Button, Modal } from 'react-bootstrap';
import { baseUrl, dateFormate } from "../../config/config";
import { apiService } from "../../service/api.service";
import { useNavigate, Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../loader";
import { useTranslation } from 'react-i18next';
import '../../i18n';
export const DriverDetail = () => {
  const { t } = useTranslation();
  // localStorage.setItem('previousPath', window.location.pathname);
  let props = useParams()
  const [bankFields, setbankFields] = useState({
    account_number: "",
     routing_number: "",
    account_holder_name: "",
    bank_name: "STRIPE TEST BANK",
    terms_and_conditions:false,
    account_holder_type:"individual"
  });
  const [deactivatedReason, setDeactivatedReason] = useState("")
  const [userId, setUserId] = useState()
  const [show, setShow] = useState(false);
  const [reasonError, setReasonError] = useState("")
  const [loader, setLoader] = useState(false);
  const [profileData, setprofileData] = useState(false);
  const [bankDetail, setbankDetail] = useState();
  const [previewImage, setpreviewImage] = useState();
  const [age, setAge] = useState();
  const [accountType, setAccountType] = useState("");
  useEffect(() => {
    get_usersDetail()
  }, [])
  const handleClose = () => { setShow(false); };
  const handleShow = (id) => {
    setUserId(props.id)
    setShow(true);
  };

  const [show_bank_info, setShow_bank_info] = useState(false);
  const [show_img_info, setShow_img_info] = useState(false);

  const handleClose_bank_info = () => setShow_bank_info(false);
  const handleShow_bank_info = () => {
    

    setShow_bank_info(true);
  }

  const handleClose_img_info = () => {
    setpreviewImage()
    setShow_img_info(false);
  }
  const handleShow_img_info = (img) => {
    console.log(img)
    setpreviewImage(img)
    setShow_img_info(true);
  }
  async function get_usersDetail() {
    setLoader(true)
    try {

      const response = await apiService.get_user_detail(props.id)
      if (response?.status == 200) {
        let responseData = response.data.data
        setprofileData(response.data.data.resource)
        calculateAge(response.data.data.resource.dob)
        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

      
          swal({ icon: 'error', text:  t("unauth"), button: "OK" }).then(() => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          });
        
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }
  const calculateAge = (dob) => {
    const dobDate = new Date(dob);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - dobDate.getFullYear();
    const monthDiff = currentDate.getMonth() - dobDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < dobDate.getDate())) {
      age--;
    }
    console.log(age)
    setAge(age);
  };

  function disableUser() {
    if (deactivatedReason.trim() !== "") {
      accountVerification("Reject", userId)
      // console.log("??????????????saveeeebutton =======", deactivatedReason)
      // userStatus(userId)
      setReasonError("")
      setShow(false)
    } else {
      setReasonError(t("enter_reason"))

    }
  }
  const accountVerification = async (status, id) => {

    setLoader(true)
    try {

      const response = await apiService.acceptReject(id, status, deactivatedReason)
      if (response?.status == 200 || response?.status == 201) {
        // get_users(page, size, role, search,statusEnabled)
        get_usersDetail()
        setLoader(false)
               // console.log("response======", status.lowerCase())
               if(status=="Accept"){
                swal(
                  t("success"),
                  t("profile_accepted"),
                  "success"
                );
              }else{
                swal( t("success"),
                t("profile_rejected"),
                "success");
              
              }
             
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          window.location.reload()
        } else {
          swal({ icon: 'error', text:  t("unauth"), button: "OK" }).then(() => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          });
        }
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }
  const getbankDetail = (userid) => { 
   apiService.getBank(userid).then((res) => {
    if(res.data.data.resource==null){
      swal("",t("no_bank"),"warning")
      return
     }
      let bankDetail={}
      let bankDatas =   res.data.data.resource
      bankDetail.account_numberShow=bankDatas.accountNumber
      bankDetail.accountNumber=bankDatas.accountNumber
      bankDetail.accountTypeLabel = bankDatas.accountType === "corriente" ? t("current") : t("saving");
      bankDetail.bankCode=bankDatas.bank
      bankDetail.documentNumber=bankDatas.documentNumber
      bankDetail.documentTypeLabel=bankDatas.documentTypeLabel
      bankDetail.expeditionDate=bankDatas.expeditionDate
      bankDetail.fullName=bankDatas.accountHolderName
      bankDetail.birthDate=bankDatas.birthDate
      bankDetail.nit= bankDatas.nit
      bankDetail.rut= bankDatas.rut
      setbankFields(bankDetail)
      setAccountType(bankDatas.accountType);
      handleShow_bank_info()
 
    }).catch((err) => {
        setLoader(false)
        // console.log(err)
        console.log("contact us api error", err.response);
    });
}
  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className='dashboard-area-heading management-heading'>
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">{t("driver_detail")}</h2>
                  </div>
                </div>
              </Col>
            </Row>
            {/*  */}
            <Row className="pb-4 justify-content-center">
              <Col md={12} lg={11}>
                <div className='user-details-section'>
                  <Row>
                    <Col md={8}>
                      <Row>
                        <Col md={12}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className='user-img'>
                              <img src={profileData?.imagePath ? baseUrl + profileData?.imagePath : require("../../assets/images/Portrait_Placeholder.png")} alt="" className='user-img' />
                            </div>
                            <div className="accept-reject">
                              {profileData.status == "Pending" ?
                                <>
                                  <Button className="accept-btn"
                                    onClick={() => {
                                      swal({
                                        title:t("are_you_sure"),
                                        text: t("accept_want"),
                                        icon: "warning",
                                        buttons: [t("cancel"), t("yes_accept")],
                                        dangerMode: true,
                                      }).then((confirmed) => {
                                        if (confirmed) {
                                          accountVerification("Accept", profileData.id)

                                        }
                                      })
                                    }}><i class="fa-solid fa-check"></i> {t("accept")}</Button>
                                  <Button className="accept-btn reject-btn ms-2"
                                    onClick={() => {
                                      swal({
                                        title: t("are_you_sure"),
                                        text:t("reject_want"),
                                        icon: "warning",
                                        buttons: [t("cancel"), t("yes_reject")],
                                        dangerMode: true,
                                      }).then((confirmed) => {
                                        if (confirmed) {
                                          handleShow(profileData.id)

                                        }
                                      })
                                    }}
                                  ><i class="fa-solid fa-close text-light"></i>  {t("reject")}</Button>

                                </> :
                                <div className="status-btns">
                                  <p className="m-0 p-0">{t("account_status")} : </p>
                                  {profileData.status == "Pending" ?
                                    <Link className="delivered waiting">{t("pending")}</Link> :
                                    <>
                                      {profileData.status == "Accept" ?
                                        <Link className="delivered">{t("accepted")}</Link>
                                        :
                                        // <Link className="delivered rejected"><b>Request Rejected</b></Link>
                                        <>
                                          {profileData.status == "Reject" ?
                                            <Link className="delivered rejected"><b>{t("request_rejected")}</b></Link> :
                                            <Link className="delivered waiting">{t("incomplete_profile")}</Link>}
                                        </>

                                      }
                                    </>}

                                </div>
                              }
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("full_name")}</p>
                            {profileData?.role == "ROLE_CULTIVATOR" ?
                              <h5>{profileData?.
                                name ? profileData?.name : "N/A"}</h5> :

                              <h5>{profileData?.
                                firstName ? profileData?.firstName + " " + profileData?.lastName : "N/A"}</h5>}
                          </div>
                        </Col>
                        {profileData?.role !== "ROLE_USER" &&
                          <Col md={6}>
                            <div className='user-text'>
                              <p>{t("email_address")}</p>
                              <h5>{profileData?.email ? profileData?.email : "N/A"}</h5>
                            </div>
                          </Col>}
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("phone_number")}
                             </p>
                            <h5>{profileData?.phone ? profileData?.phone : "N/A"}</h5>
                          </div>
                        </Col>
                        {profileData?.role == "ROLE_CULTIVATOR" ?
                          <Col md={6}>
                            <div className='user-text'>
                              <p>{t("experience")}</p>
                              <h5>{profileData?.cultivator?.yearOfExperience ? profileData?.cultivator?.yearOfExperience : "N/A"}</h5>
                            </div>
                          </Col> : null
                        }
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("age")}</p>
                            <h5>{age ? age : "N/A"}</h5>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("gender")}</p>
                            <h5>{profileData?.gender ? profileData?.gender : "N/A"}</h5>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("user_type")}</p>
                            <h5>{t("driver")}</h5>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("address")}</p>
                            <h5>{profileData?.address ? profileData?.address : "N/A"}</h5>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("vehical_modal")}</p>
                            <h5>{profileData?.driver?.vehicleModel ? profileData?.driver.vehicleModel : "N/A"}</h5>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("vehical_type")}</p>
                            <h5>{profileData?.driver?.vehicleType ? profileData?.driver.vehicleType : "N/A"}</h5>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className='user-text'>
                            <p>{t("license")}</p>
                            <h5>{profileData?.driver?.vehicleNumber ? profileData?.driver.vehicleNumber : "N/A"}</h5>
                          </div>
                        </Col>

                        {/* <Col md={6}>
                          <div className='user-text'>
                            <p>Insurance Number</p>
                            <h5>{profileData?.driver?.insuranceNumber ? profileData?.driver.insuranceNumber : "N/A"}</h5>
                          </div>
                        </Col> */}
                    
                        {profileData.reason &&
                          <Col md={6}>
                            <div className='user-text'>
                              <p>{t("rejection_reason")}</p>
                              <h5>{profileData?.reason ? profileData?.reason : "N/A"}</h5>
                            </div>
                          </Col>}
                      </Row>
                    </Col>
                    <Col md={4}>
                      <div className='document-information'>
                        <h4>{t("vehical_info")}</h4>
                        <Row>
                          <Col md={6} className="mt-3">
                            <div className='document-information'>
                              <p className="vehilce-txt"><b>{t("driving_licence")}
                                </b></p>
                              <span>{t("front")}
                                </span>
                              {profileData?.driver?.drivingLicence ?
                                <>
                                  {profileData?.driver?.drivingLicence.includes('pdf') ?
                                    <div className='uploaded-files mt-3'>


                                      <img src={require("../../assets/images/file.svg").default} alt="" className='user-img' />
                                      <a href={baseUrl + profileData?.driver?.drivingLicence} target="_blank" ><p>{t("front")}
                                      {t("preview_driving_image")}</p></a>
                                    </div> :

                                    <div className='uploaded-files mt-3'>


                                      <img src={profileData?.driver?.drivingLicence ? baseUrl + profileData?.driver?.drivingLicence : require("../../assets/images/Portrait_Placeholder.png")} alt="" className='user-img' />
                                      <a href="#" onClick={() => handleShow_img_info(baseUrl + profileData?.driver?.drivingLicence)} ><p>{t("preview_driving_image")}
                                       </p></a>
                                    </div>}
                                </>
                                : <div className='uploaded-files mt-3'>N/A</div>

                              }
                            </div>
                          </Col>
                          <Col md={6} className="mt-3">
                            <div className='document-information'>
                              <p className="vehilce-txt text-light"><b>{t("driving_licence")}</b></p>
                              <span>{t("back_id")}
                                </span>
                              {profileData?.driver?.licenceBack ?
                                <>

                                  <div className='uploaded-files mt-3'>

                                    <img src={profileData?.driver?.licenceBack ? baseUrl + profileData?.driver?.licenceBack : require("../../assets/images/Portrait_Placeholder.png")} alt="" className='user-img' />
                                    <a href="#" onClick={() => handleShow_img_info(baseUrl + profileData?.driver?.licenceBack)} ><p>{t("preview_back")}
                                     </p></a>
                                  </div>
                                </>
                                : <div className='uploaded-files mt-3'>N/A</div>}
                            </div>
                          </Col>
                          <Col md={12} className="mt-3">

                          <p className="vehilce-txt"><b>{t("insurance_image")}
                            </b></p>

                            <div className='uploaded-files mt-3'>

                              <img src={profileData?.driver?.insuranceImage ? baseUrl + profileData?.driver?.insuranceImage : require("../../assets/images/Portrait_Placeholder.png")} alt="" className='user-img' />
                              <a href="#" onClick={() => handleShow_img_info(baseUrl + profileData?.driver?.insuranceImage)} ><p>{t("preview_insurance_image")}
                                </p></a>
                            </div>

                          </Col>
                          <Col md={6} className="mt-3">
                            <p className="vehilce-txt"><b>{t("vehical_image")}
                             </b></p>
                            <div class="uploaded-files vehicle-imgs mt-3">
                              {profileData?.driver?.vehicleType?
                              <>
                              {/* car */}
                              {profileData?.driver?.vehicleType === "Car"?
                              <svg style={{ color: profileData?.driver?.vehicleColorCode }} width="148" height="148" viewBox="0 0 148 148" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1694_720)">
                                  <path d="M122.166 77.9966C128.334 77.9966 133.334 82.9959 133.334 89.1646C133.334 89.6401 133.296 90.1082 133.236 90.5673C132.543 96.0721 127.858 100.33 122.168 100.33C116.324 100.33 111.532 95.8373 111.046 90.1157C111.021 89.8031 111 89.4846 111 89.1631C110.998 82.9959 115.998 77.9966 122.166 77.9966ZM127.756 84.9564L125.48 87.234C125.657 87.5375 125.796 87.865 125.886 88.215H129.095C128.93 87.0007 128.465 85.8911 127.756 84.9564ZM129.11 90.1486H125.875C125.784 90.4925 125.649 90.8186 125.47 91.1176L127.752 93.3982C128.459 92.471 128.935 91.3614 129.11 90.1486ZM123.133 96.102C124.342 95.933 125.441 95.4589 126.372 94.7576L124.102 92.486C123.802 92.6609 123.475 92.7896 123.133 92.8793V96.102ZM123.133 85.4499C123.484 85.5426 123.813 85.6802 124.12 85.8611L126.394 83.5866C125.459 82.8762 124.35 82.4067 123.133 82.2377V85.4499ZM121.199 82.2347C119.982 82.4037 118.871 82.8762 117.937 83.5851L120.21 85.8597C120.515 85.6787 120.847 85.5411 121.197 85.4484L121.199 82.2347ZM121.199 96.099V92.8808C120.855 92.7896 120.53 92.6624 120.231 92.486L117.96 94.7576C118.89 95.4589 119.991 95.93 121.199 96.099ZM116.575 93.4087L118.865 91.1162C118.685 90.8156 118.54 90.494 118.449 90.1501H115.24C115.41 91.3584 115.866 92.477 116.575 93.4087ZM118.444 88.215C118.536 87.865 118.673 87.5375 118.85 87.234L116.571 84.9564C115.866 85.8911 115.401 87.0007 115.234 88.2165H118.444V88.215Z" fill="currentColor" />
                                  <path d="M29.435 77.9966C35.6023 77.9966 40.603 82.9959 40.603 89.1646C40.603 89.6401 40.5641 90.1082 40.5043 90.5673C39.8119 96.0721 35.1267 100.33 29.438 100.33C23.5938 100.33 18.8024 95.8373 18.3149 90.1157C18.2894 89.8031 18.2685 89.4846 18.2685 89.1631C18.2655 82.9959 23.2663 77.9966 29.435 77.9966ZM35.025 84.9564L32.7504 87.234C32.9269 87.5375 33.066 87.865 33.1557 88.215H36.3649C36.1989 87.0007 35.7339 85.8911 35.025 84.9564ZM36.3769 90.1486H33.1422C33.051 90.4925 32.9164 90.8186 32.737 91.1176L35.0175 93.3982C35.7279 92.471 36.2049 91.3614 36.3769 90.1486ZM30.4011 96.102C31.6109 95.933 32.7101 95.4589 33.6402 94.7576L31.3701 92.486C31.071 92.6609 30.7435 92.7896 30.4011 92.8793V96.102ZM30.4011 85.4499C30.7525 85.5426 31.0815 85.6802 31.3881 85.8611L33.6627 83.5866C32.728 82.8762 31.6199 82.4067 30.4011 82.2377V85.4499ZM28.466 82.2347C27.2487 82.4037 26.1376 82.8762 25.2044 83.5851L27.479 85.8597C27.784 85.6787 28.116 85.5381 28.466 85.4484V82.2347ZM28.466 96.099V92.8808C28.122 92.7896 27.7975 92.6624 27.4984 92.486L25.2268 94.7576C26.157 95.4589 27.2591 95.93 28.466 96.099ZM23.8435 93.4087L26.1331 91.1191C25.9536 90.8171 25.8071 90.497 25.7173 90.1516H22.5081C22.6786 91.3584 23.1332 92.477 23.8435 93.4087ZM25.7129 88.215C25.8041 87.865 25.9402 87.5375 26.1181 87.234L23.8406 84.9564C23.1347 85.8911 22.6711 87.0007 22.5021 88.2165H25.7129V88.215Z" fill="currentColor" />
                                  <path d="M2.61864 72.2151V63.9513C2.61864 62.6218 3.5847 61.4958 4.89771 61.2909C8.04861 60.7974 13.6341 59.8657 16.1435 59.0926C19.7415 57.9829 33.6432 52.0969 38.457 50.9917C43.2724 49.8866 64.9833 43.9856 85.7775 51.1936C89.2963 52.4124 105.126 59.9794 114.433 63.9139C117.174 63.9737 144.306 69.6295 145.751 73.5057C147.196 77.3834 147.649 79.2647 147.915 80.2487C148.181 81.2327 147.915 85.9418 145.899 89.2214C143.844 90.3833 139.889 91.1639 135.316 91.6874C135.368 91.4077 135.425 91.1325 135.461 90.8499C135.54 90.2457 135.577 89.6939 135.577 89.1675C135.577 81.7725 129.563 75.7549 122.166 75.7549C114.77 75.7549 108.755 81.771 108.755 89.1675C108.755 89.5474 108.78 89.9242 108.809 90.2951C108.879 91.1251 109.029 91.9311 109.246 92.7072C109.235 92.7072 109.21 92.7072 109.21 92.7072L42.6383 91.3673C42.6682 91.1953 42.7071 91.0249 42.7295 90.8499C42.8088 90.2487 42.8462 89.6954 42.8462 89.1675C42.8462 81.7725 36.8315 75.7563 29.435 75.7563C22.0385 75.7563 16.0223 81.771 16.0223 89.1675C16.0223 89.1959 16.0253 89.2199 16.0268 89.2468L15.7427 89.2228L4.36082 87.0799C4.36082 87.0799 0.00160217 84.7589 0.00160217 78.3734C0.000106812 74.1143 2.61864 72.2151 2.61864 72.2151ZM68.6561 64.3805L93.5179 65.1417C93.0603 61.4165 95.2661 60.5805 95.2661 60.5805C85.3827 51.6093 65.4633 51.3058 65.4633 51.3058L68.6561 64.3805ZM40.7541 63.2424L63.4101 64.0036L61.3568 51.3073C49.6475 51.1547 42.9584 53.7389 42.9584 53.7389L39.613 59.1374L40.7541 63.2424ZM27.9814 63.1647H34.2937L40.6045 54.4986C35.8699 56.3828 32.0805 58.2402 29.8238 59.4141C28.454 60.1259 27.7063 61.6468 27.9814 63.1647Z" fill="currentColor" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1694_720">
                                    <rect width="148" height="148" fill="currentColor" transform="matrix(-1 0 0 1 148 0)" />
                                  </clipPath>
                                </defs>
                              </svg>:
                              <>
                               {/* cargo-truck 1 */}
                              {profileData?.driver?.vehicleType=="Truck"?
                             
                              <svg width="148" height="148" style={{ color: profileData?.driver?.vehicleColorCode }} viewBox="0 0 148 148" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1694_741)">
                                  <path d="M100.995 31.8027H0V88.6083H100.995V31.8027Z" fill="white" />
                                  <path d="M148 72.4263L137.617 49.207H105.524V92.8882H68.5111H33.996H12.1924V102.818H22.5759C22.4929 103.383 22.4357 103.956 22.4357 104.543C22.4357 110.979 27.6114 116.197 33.9964 116.197C40.3814 116.197 45.5571 110.979 45.5571 104.543C45.5571 103.956 45.4999 103.383 45.4169 102.818H57.0917C57.0087 103.383 56.9514 103.956 56.9514 104.543C56.9514 110.979 62.1271 116.197 68.5121 116.197C74.8965 116.197 80.0725 110.979 80.0725 104.543C80.0725 103.956 80.0156 103.383 79.9326 102.818H105.526H107.796C107.713 103.383 107.656 103.956 107.656 104.543C107.656 110.979 112.832 116.197 119.217 116.197C125.601 116.197 130.777 110.979 130.777 104.543C130.777 103.956 130.72 103.383 130.637 102.818H148V72.4263ZM33.996 109.105C31.4969 109.105 29.4707 107.062 29.4707 104.543C29.4707 103.932 29.5919 103.351 29.8074 102.818C30.4822 101.154 32.1021 99.9805 33.9957 99.9805C35.8893 99.9805 37.5092 101.154 38.1839 102.818C38.3995 103.351 38.5206 103.932 38.5206 104.543C38.521 107.062 36.4952 109.105 33.996 109.105ZM68.5111 109.105C66.0119 109.105 63.9858 107.062 63.9858 104.543C63.9858 103.932 64.107 103.351 64.3225 102.818C64.9973 101.154 66.6171 99.9805 68.5107 99.9805C70.4044 99.9805 72.0242 101.154 72.6986 102.818C72.9145 103.351 73.0357 103.932 73.0357 104.543C73.036 107.062 71.0103 109.105 68.5111 109.105ZM119.215 109.105C116.716 109.105 114.69 107.062 114.69 104.543C114.69 103.932 114.811 103.351 115.026 102.818C115.701 101.154 117.321 99.9805 119.215 99.9805C121.108 99.9805 122.728 101.154 123.402 102.818C123.618 103.351 123.74 103.932 123.74 104.543C123.74 107.062 121.714 109.105 119.215 109.105ZM113.166 71.9893C112.992 71.9893 112.851 71.8487 112.851 71.6752V55.9884C112.851 55.815 112.992 55.6744 113.166 55.6744H132.922C133.338 55.6744 133.715 55.921 133.882 56.3029L140.736 71.9893H113.166Z" fill="currentColor" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1694_741">
                                    <rect width="148" height="148" fill="currentColor" />
                                  </clipPath>
                                </defs>
                              </svg>:
                            
                                 <>

                          {profileData?.driver?.vehicleType=="Bicycle"?
                             
                             <svg width="150" height="150"  style={{ color: profileData?.driver?.vehicleColorCode }} viewBox="0 0 150 150" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                             <path d="M35.2607 92.1375H74.2607C74.7683 92.1372 75.2676 92.0081 75.7117 91.7623C76.1558 91.5165 76.5304 91.162 76.8002 90.732L100.869 52.389C101.168 51.9127 101.327 51.362 101.329 50.7996C101.33 50.2372 101.173 49.6858 100.876 49.2083C100.578 48.7309 100.153 48.3465 99.6481 48.0992C99.143 47.8518 98.5785 47.7515 98.0192 47.8095L59.0192 51.8205C58.5913 51.8646 58.1779 52.0002 57.807 52.2181C57.4361 52.436 57.1165 52.7311 56.8697 53.0835L32.8037 87.4155C32.4885 87.865 32.3028 88.3924 32.2667 88.9402C32.2306 89.488 32.3455 90.0352 32.5989 90.5222C32.8523 91.0092 33.2345 91.4174 33.7039 91.7022C34.1732 91.987 34.7117 92.1375 35.2607 92.1375ZM72.6002 86.1375H41.0267L60.9947 57.6495L92.5187 54.408L72.6002 86.1375Z"/>
                             <path d="M35.2605 112.398C48.0855 112.398 58.5195 101.962 58.5195 89.1375C58.5195 76.3125 48.0855 65.877 35.2605 65.877C22.4355 65.877 12 76.3125 12 89.1375C12 101.962 22.434 112.398 35.2605 112.398ZM35.2605 71.877C38.6743 71.877 42.0115 72.8893 44.8499 74.7859C47.6884 76.6825 49.9007 79.3782 51.2071 82.5321C52.5136 85.6861 52.8554 89.1566 52.1894 92.5048C51.5234 95.853 49.8795 98.9285 47.4655 101.342C45.0516 103.756 41.9761 105.4 38.6279 106.066C35.2797 106.732 31.8092 106.39 28.6552 105.084C25.5013 103.778 22.8056 101.565 20.9089 98.7269C19.0123 95.8884 18 92.5513 18 89.1375C18.0048 84.5611 19.8248 80.1736 23.0608 76.9377C26.2967 73.7018 30.6842 71.8817 35.2605 71.877Z" />
                             <path d="M114.739 112.398C127.566 112.398 138 101.962 138 89.1375C138 76.3125 127.566 65.877 114.739 65.877C101.913 65.877 91.4805 76.3125 91.4805 89.1375C91.4805 101.962 101.914 112.398 114.739 112.398ZM114.739 71.877C118.153 71.8767 121.491 72.8887 124.329 74.7851C127.168 76.6816 129.381 79.3772 130.687 82.5311C131.994 85.685 132.336 89.1556 131.67 92.5039C131.004 95.8522 129.36 98.9279 126.947 101.342C124.533 103.756 121.457 105.4 118.109 106.066C114.761 106.732 111.29 106.391 108.136 105.084C104.982 103.778 102.286 101.566 100.39 98.727C98.4928 95.8885 97.4805 92.5513 97.4805 89.1375C97.4857 84.5615 99.3056 80.1745 102.541 76.9387C105.777 73.7029 110.164 71.8825 114.739 71.877Z" />
                             <path d="M73.5644 103.767C74.3278 103.766 75.0621 103.474 75.6178 102.951C76.1735 102.428 76.5088 101.712 76.5554 100.95L77.2499 89.3176C77.2785 88.8459 77.1952 88.3741 77.0069 87.9406L62.3249 54.1816V44.2666C62.3249 43.471 62.0088 42.7079 61.4462 42.1453C60.8836 41.5827 60.1205 41.2666 59.3249 41.2666C58.5292 41.2666 57.7662 41.5827 57.2036 42.1453C56.6409 42.7079 56.3249 43.471 56.3249 44.2666V54.8056C56.3248 55.2184 56.4096 55.6269 56.5739 56.0056L71.2244 89.6746L70.5674 100.587C70.5198 101.381 70.7895 102.162 71.3172 102.757C71.8449 103.352 72.5874 103.713 73.3814 103.761C73.4429 103.766 73.4999 103.767 73.5644 103.767Z" />
                             <path d="M67.3498 103.767H79.7593C80.5549 103.767 81.318 103.451 81.8806 102.888C82.4432 102.326 82.7593 101.563 82.7593 100.767C82.7593 99.9714 82.4432 99.2084 81.8806 98.6458C81.318 98.0832 80.5549 97.7671 79.7593 97.7671H67.3498C66.5541 97.7671 65.7911 98.0832 65.2285 98.6458C64.6658 99.2084 64.3498 99.9714 64.3498 100.767C64.3498 101.563 64.6658 102.326 65.2285 102.888C65.7911 103.451 66.5541 103.767 67.3498 103.767Z" fill="white"/>
                             <path d="M114.741 92.1375C115.237 92.1374 115.726 92.0141 116.163 91.7788C116.6 91.5435 116.971 91.2035 117.245 90.7892C117.518 90.3749 117.684 89.8993 117.728 89.4051C117.773 88.9108 117.693 88.4133 117.498 87.957L101.328 50.1796V43.6021H107.832C108.303 43.6028 108.753 43.7901 109.086 44.1227C109.419 44.4553 109.606 44.9062 109.607 45.3766V46.02C109.606 46.4904 109.419 46.9413 109.086 47.2739C108.753 47.6065 108.303 47.7938 107.832 47.7946C107.037 47.7946 106.273 48.1106 105.711 48.6732C105.148 49.2358 104.832 49.9989 104.832 50.7946C104.832 51.5902 105.148 52.3533 105.711 52.9159C106.273 53.4785 107.037 53.7946 107.832 53.7946C109.893 53.7922 111.869 52.9723 113.327 51.5148C114.784 50.0573 115.604 48.0812 115.607 46.02V45.3766C115.604 43.3154 114.784 41.3393 113.327 39.8818C111.869 38.4243 109.893 37.6044 107.832 37.6021H98.3282C97.5326 37.6021 96.7695 37.9181 96.2069 38.4807C95.6443 39.0433 95.3282 39.8064 95.3282 40.6021V50.7946C95.3285 51.2003 95.4106 51.6018 95.5697 51.9751L111.983 90.318C112.214 90.8581 112.598 91.3184 113.089 91.6418C113.579 91.9653 114.154 92.1377 114.741 92.1375Z" />
                             <path d="M66.9376 48.3961C67.8014 48.3916 68.6307 48.0561 69.2547 47.4588C69.8787 46.8615 70.2501 46.0477 70.2923 45.1849C70.3346 44.3221 70.0446 43.4759 69.482 42.8204C68.9193 42.1649 68.1269 41.75 67.2676 41.6611L52.1176 40.1611C51.2269 40.0726 50.3375 40.3415 49.645 40.9088C48.9526 41.476 48.5139 42.2951 48.4254 43.1858C48.3369 44.0766 48.6058 44.966 49.1731 45.6584C49.7403 46.3508 50.5594 46.7896 51.4501 46.8781L66.6001 48.3781C66.7122 48.3901 66.8249 48.3961 66.9376 48.3961Z" fill="white"/>
                             </svg>
                             
                             :
                              
                              <svg width="124" height="148" style={{ color: profileData?.driver?.vehicleColorCode }} viewBox="0 0 124 148" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M99.8373 81.1963H101.525C103.209 81.1963 104.573 79.8319 104.573 78.1484V75.0219C104.573 73.3384 103.209 71.974 101.525 71.974C100.672 71.974 99.8998 71.4699 99.5598 70.6883L93.3277 56.434C93.5265 56.4479 100.839 56.471 100.839 56.471C103.297 56.471 105.299 54.4707 105.299 52.0102V44.8553C105.299 42.3972 103.299 40.3945 100.839 40.3945H93.922C91.6927 40.3945 89.63 41.3565 88.1222 42.9475C85.1298 42.9892 82.7063 45.4288 82.7063 48.4328C82.7063 50.447 83.8094 52.1929 85.4305 53.148C85.4305 54.4175 85.6525 55.6825 86.1497 56.8665C94.699 77.2165 83.4325 90.0855 79.9938 93.3808C79.4249 93.9266 78.6872 94.2226 76.7632 94.2226H57.3798C56.5959 94.2226 55.8235 93.9289 55.2732 93.3716C48.7149 86.744 51.1893 81.4206 53.6359 78.5623C54.7852 77.2211 56.3993 76.4487 58.1198 76.0463C59.9698 75.6139 61.3388 74.5895 62.3402 73.4286C64.019 71.4815 62.546 68.4521 59.9768 68.4521H45.926V45.0218H2.65221V66.1396C2.65221 68.6903 4.72653 70.7646 7.27722 70.7646H22.7062C10.3598 75.7735 3.50322 87.7753 0.607965 94.2434C-0.0765347 95.7743 0.478465 98.5262 2.37934 98.5262H41.7658C42.3902 98.5262 43.0007 98.7181 43.5141 99.0765L48.2987 102.427C48.812 102.786 49.4202 102.978 50.0469 102.978H79.1243C80.0377 102.978 80.9049 102.568 81.483 101.861L97.4809 82.3132C98.059 81.6056 98.9239 81.1963 99.8373 81.1963Z" fill="currentColor" />
                                <path d="M36.6758 29.5146H11.902C1.62526 29.5146 2.65201 40.3996 2.65201 40.3996H45.9258C45.9258 40.3996 47.1908 29.5146 36.6758 29.5146Z" fill="currentColor" />
                                <path d="M108.511 100.607C108.93 101.301 109.187 102.108 109.187 102.978C109.187 105.524 107.122 107.589 104.576 107.589C102.871 107.589 101.398 106.652 100.6 105.279C100.032 105.431 99.4464 105.538 98.8498 105.538H89.2969C90.5225 112.873 96.8981 118.488 104.578 118.488C113.127 118.488 120.085 111.529 120.085 102.98C120.085 102.182 120.011 101.391 119.891 100.61H108.514L108.511 100.607Z" fill="currentColor" />
                                <path d="M23.009 107.589C20.5207 107.589 18.5089 105.616 18.4164 103.151H7.5083C7.60311 111.622 14.5152 118.486 23.009 118.486C31.5028 118.486 38.4149 111.62 38.5074 103.151H27.6016C27.5091 105.616 25.4949 107.589 23.009 107.589Z" fill="currentColor" />
                                <path d="M110.748 81.1963H108.562C106.375 86.5474 100.586 85.8213 100.586 85.8213L88.2354 100.913H98.8497C99.3631 100.913 99.8603 100.728 100.251 100.392L104.767 96.5027C105.156 96.1674 105.653 95.9824 106.169 95.9824H121.517C122.796 95.9824 123.829 94.8609 123.656 93.5936C122.296 83.6799 110.877 81.1963 110.748 81.1963Z" fill="currentColor" />
                              </svg>}
                              </>
                              }

                               </>  }  
                               </>:"N/A" }  
                            </div>
                          </Col>
                          <Col md={6} className="mt-3">
                            <p className="vehilce-txt"><b>{t("vehical_color")}
                             </b></p>
                            {profileData?.driver?.vehicleColor ?
                              <div className="vehicle-img-box">
                                <span className="blue-color" style={{ color: profileData?.driver?.vehicleColorCode,background: profileData?.driver?.vehicleColorCode }} ></span>
                                {profileData?.driver?.vehicleColor}
                                {/* <img src={baseUrl +profileData?.driver?.vehicleImage} alt="" className="vehicle-img-box" /> */}
                              </div> :
                              <div >N/A</div>}

                          </Col>

                          <Col md={12} className="mt-3">
                            <a onClick={()=>getbankDetail(props.id)} target="_blank" className="bank-view-txt"><p><i class="fa fa-university me-2" aria-hidden="true"></i>{t("view_bank")}
                            </p></a>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col md={4}>


                    </Col>

                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      {/* bank information details start */}
      <Modal show={show_bank_info} onHide={handleClose_bank_info}>
        <Modal.Header closeButton>
          <Modal.Title><b> {t("bank_info")}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bank-information-box">
            <Row>
            <Col md={6}>
                <div className='user-text'>
                  <p>{t("bank_code")}</p>
                  <h5>{bankFields?.bankCode ? bankFields.bankCode : "N/A"}</h5>
                </div>
              </Col>
            
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("acc_holder_name")}</p>
                  <h5>{bankFields?.fullName ? bankFields.fullName : "N/A"}</h5>
                </div>
              </Col>
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("account_type")}</p>
                  <h5>{bankFields?.accountTypeLabel ? bankFields?.accountTypeLabel : "N/A"}</h5>
                </div>
              </Col>
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("acc_number")}</p>
                  <h5>{bankFields?.account_numberShow ? bankFields?.account_numberShow : "N/A"}</h5>
                </div>
              </Col>

              {accountType === "corriente" && (
                <>
                  <Col md={6}>
                    <div className='user-text'>
                      <p>NIT</p>
                      <h5>{bankFields?.nit ? bankFields.nit : "N/A"}</h5>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className='user-text'>
                      <p>RUT</p>
                      <h5>{bankFields?.rut ? bankFields.rut : "N/A"}</h5>
                    </div>
                  </Col>
                </>
              )}


              {/* <Col md={6}>
                <div className='user-text'>
                  <p>{t("birthDate")}</p>
                  <h5>{bankFields?.birthDate ? bankFields.birthDate : "N/A"}</h5>
                </div>
              </Col>
              <Col md={6}>
                <div className='user-text'>
                  <p>{t("expedition_date")}</p>
                  <h5>{bankFields?.expeditionDate ? bankFields.expeditionDate : "N/A"}</h5>
                </div>
              </Col> */}
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose_bank_info}>
          {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* bank information details end */}

      {/* bank information details start */}
      <Modal show={show_img_info} onHide={handleClose_img_info} className="preview-image-popup" >
        <Modal.Header closeButton>
          {/* <Modal.Title><b>{t("preview_image")}</b></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="bank-information-box">
            <Row>
              <Col md={12}>
                <div className="vehicle-img-box-main">
                  {previewImage ?
                    <img src={previewImage} alt="image" className="vehicle-img-box" /> :
                    "Loading..."}
                </div>
              </Col>

            </Row>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose_img_info}>
          {t("close")}
          </Button>
        </Modal.Footer> */}
      </Modal>
      {/* bank information details end */}

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t("rejection_reason")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <textarea maxLength={500} onChange={(e) => setDeactivatedReason(e.target.value)} className="form-control">

          </textarea>
          <span className="text-danger">{reasonError}</span>


        </Modal.Body>
        <Modal.Footer className="pt-3">
          <Button variant="secondary" onClick={handleClose} className="close-btn">
          {t("close")}
          </Button>
          <Button variant="primary" onClick={disableUser}>
          {t("submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
